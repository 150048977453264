import { RoleToView, ReturnRoleConfig } from 'app/common/interface/roleToView';
import { CommenString } from 'app/common/enum/type.enum'
import { RoleConfig } from 'app/common/services/RoleToView/RoleToView.config';

export class TransRoleToView implements RoleToView {

    userRole: string;
    constructor(_userRole: string){
        this.userRole = _userRole;
        
    }

    getRoleToComponent(): ReturnRoleConfig{
        let roleConfList = {ComponentConfig: {}};
        switch (this.userRole){
            case CommenString.AdminRole:
                roleConfList.ComponentConfig = RoleConfig.Admin.ComponentConfig;
                break;
            case CommenString.GuLeadRole:
                roleConfList.ComponentConfig = RoleConfig.GuLead.ComponentConfig;
                break;
            case CommenString.DocumentOwnerRole: 
                roleConfList.ComponentConfig = RoleConfig.DocumentOwner.ComponentConfig;
                break;
            case CommenString.StandardRole: 
                roleConfList.ComponentConfig = RoleConfig.Standard.ComponentConfig;
                break;
            case CommenString.DocumentOwnAndGuLead:
                roleConfList.ComponentConfig = RoleConfig.DocumentOwnerAndGuLead.ComponentConfig;
                break;
            default: 
                roleConfList.ComponentConfig = RoleConfig.ReadOnly.ComponentConfig;
        }
        return roleConfList;
    }

}