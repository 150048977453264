
export const RoleConfig = {
    ReadOnly: {
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: false,
                // PBI 132291 Hide My pages for readonly  user
                mypages: false,
                // mypages: {
                //     ExpiringContracts: false,
                //     myfavorite: false,
                //     myrequiredactions: false,
                //     myinprogresssubmissions: false,
                //     mypendingsubmissions: false,
                //     myrecords: false
                // },
                administrator: false,
                dashboard: false,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:false,
                    sipviewrecord: false,
                    spviewrecord: true,
                    requesterViewPendingRecord:false,
                    adminViewRecord:false,
                    addSupportingDocument:false,
                    adminViewActiveRecord:false,
                    docOwnerViewActiveRecord:false,
                }
            },
            apphome: {
                cards: {
                    disable: true,
                    number: false
                },
                tabmenu: {
                    expiringcontracts: false
                }
            },
            terminatedEdit:{
                disable:true
            }
        }
    },
    Standard: {
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: true,
                mypages: {
                    ExpiringContracts: true,
                    myfavorite: false,
                    myrequiredactions: true,
                    myinprogresssubmissions: true,
                    mypendingsubmissions: true,
                    myrecords: true
                },
                administrator: false,
                dashboard: true,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:true,
                    sipviewrecord:true,
                    spviewrecord:true,
                    requesterViewPendingRecord:true,
                    adminViewRecord:false,
                    addSupportingDocument:true,
                    adminViewActiveRecord:false,
                    docOwnerViewActiveRecord:false,
                }
            },
            apphome: {
                cards: {
                    disable: false,
                    number: true
                },
                tabmenu: {
                    expiringcontracts: true
                }
            },
            terminatedEdit:{
                disable:true
            }
        }
    },
    DocumentOwner: {
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: true,
                mypages: {
                    ExpiringContracts: true,
                    myfavorite: false,
                    myrequiredactions: true,
                    myinprogresssubmissions: true,
                    mypendingsubmissions: true,
                    myrecords: true
                },
                administrator: false,
                dashboard: true,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:true,
                    sipviewrecord:true,
                    spviewrecord:true,
                    requesterViewPendingRecord:true,
                    adminViewRecord:false,
                    addSupportingDocument:true,
                    adminViewActiveRecord:false,
                    docOwnerViewActiveRecord:true,
                }
            },
            apphome: {
                cards: {
                    disable: false,
                    number: true
                },
                tabmenu: {
                    expiringcontracts: true
                }
            },
            terminatedEdit:{
                disable:true
            }
        }
    }, 
    GuLead: {
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: true,
                mypages: {
                    ExpiringContracts: true,
                    myfavorite: false,
                    myrequiredactions: true,
                    myinprogresssubmissions: true,
                    mypendingsubmissions: true,
                    myrecords: true
                },
                administrator: false,
                dashboard: true,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:true,
                    sipviewrecord:true,
                    spviewrecord:true,
                    requesterViewPendingRecord:true,
                    adminViewRecord:false,
                    addSupportingDocument:true,
                    adminViewActiveRecord:false,
                    docOwnerViewActiveRecord:false,
                }
            },
            apphome: {
                cards: {
                    disable: false,
                    number: true
                },
                tabmenu: {
                    expiringcontracts: true
                }
            },
            terminatedEdit:{
                disable:true
            }
        }
    },
    DocumentOwnerAndGuLead:{
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: true,
                mypages: {
                    ExpiringContracts: true,
                    myfavorite: false,
                    myrequiredactions: true,
                    myinprogresssubmissions: true,
                    mypendingsubmissions: true,
                    myrecords: true
                },
                administrator: false,
                dashboard: true,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:true,
                    sipviewrecord:true,
                    spviewrecord:true,
                    requesterViewPendingRecord:true,
                    adminViewRecord:false,
                    addSupportingDocument:true,
                    adminViewActiveRecord:false,
                    docOwnerViewActiveRecord:true,
                }
            },
            apphome: {
                cards: {
                    disable: false,
                    number: true
                },
                tabmenu: {
                    expiringcontracts: true
                }
            },
            terminatedEdit:{
                disable:true
            }
        }
    },
    Admin: {
        ComponentConfig: {
            menuheader: {
                home:true,
                newrecord: true,
                mypages: {
                    ExpiringContracts: true,
                    myfavorite: false,
                    myrequiredactions: true,
                    myinprogresssubmissions: true,
                    mypendingsubmissions: true,
                    myrecords: true,
                    myuseraccessrequests: true
                },
                administrator: true,
                dashboard:true,
                mainsearch: true,
                searchrecord:true,
                viewrecord:{
                    standardViewActiveRecord : true,
                    history:true,
                    recordFamily:true,
                    myRaViewRecord:true,
                    sipviewrecord:true,
                    spviewrecord:true,
                    requesterViewPendingRecord:true,
                    adminViewRecord:true,
                    addSupportingDocument:true,
                    adminViewActiveRecord:true,
                    terminationView:true,
                    docOwnerViewActiveRecord:false,
                }

            },
            apphome: {
                cards: {
                    disable: false,
                    number: true
                },
                tabmenu: {
                    expiringcontracts: true
                }
            },
            terminatedEdit:{
                disable:false
            }
        }
    }
}
